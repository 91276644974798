<div class="spinner__container">
  <svg
    class="spinner__svg"
    width="200"
    height="200"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M91.4761 77.5115L98.278 88.7304L116.215 2.10997L91.4761 77.5115Z"
      fill="#FFB1B3" />
    <path
      d="M116.215 2.10997L98.278 88.7304L102.457 88.7304L116.287 75.4243L116.215 2.10997Z"
      fill="white" />
    <path
      d="M98.278 88.7304L97.6364 89.1182L97.8559 89.4801L98.2791 89.4801L98.278 88.7304ZM91.4761 77.5115L90.7631 77.277L90.6557 77.6044L90.8345 77.8993L91.4761 77.5115ZM116.215 2.10997L116.965 2.11032L115.502 1.87549L116.215 2.10997ZM116.287 75.4243L116.808 75.9647L117.038 75.7436L117.037 75.4246L116.287 75.4243ZM102.457 88.7304L102.458 89.4801L102.76 89.4801L102.978 89.2708L102.457 88.7304ZM98.9196 88.3427L92.1177 77.1238L90.8345 77.8993L97.6364 89.1182L98.9196 88.3427ZM92.1891 77.746L116.928 2.34444L115.502 1.87549L90.7631 77.277L92.1891 77.746ZM115.48 1.95705L97.5433 88.5775L99.0128 88.8834L116.95 2.26289L115.48 1.95705ZM115.465 2.10961L115.537 75.4239L117.037 75.4246L116.965 2.11032L115.465 2.10961ZM115.766 74.8839L101.936 88.19L102.978 89.2708L116.808 75.9647L115.766 74.8839ZM102.456 87.9807L98.2769 87.9807L98.2791 89.4801L102.458 89.4801L102.456 87.9807Z"
      fill="#D10911" />
    <path
      d="M124.223 103.48L111.101 103.745L177.246 162.646L124.223 103.48Z"
      fill="#FFB1B3" />
    <path
      d="M177.246 162.646L111.101 103.745L109.016 107.361L113.648 125.989L177.246 162.646Z"
      fill="white" />
    <path
      d="M111.101 103.745L111.086 102.996L110.663 103.004L110.452 103.37L111.101 103.745ZM124.223 103.48L124.781 102.98L124.552 102.723L124.207 102.73L124.223 103.48ZM177.246 162.646L176.872 163.296L177.804 162.146L177.246 162.646ZM113.648 125.989L112.921 126.169L112.998 126.479L113.274 126.639L113.648 125.989ZM109.016 107.361L108.366 106.985L108.215 107.247L108.288 107.541L109.016 107.361ZM111.117 104.495L124.239 104.23L124.207 102.73L111.086 102.996L111.117 104.495ZM123.665 103.98L176.687 163.147L177.804 162.146L124.781 102.98L123.665 103.98ZM177.744 162.086L111.6 103.186L110.603 104.305L176.747 163.206L177.744 162.086ZM177.62 161.997L114.022 125.339L113.274 126.639L176.872 163.296L177.62 161.997ZM114.376 125.809L109.743 107.18L108.288 107.541L112.921 126.169L114.376 125.809ZM109.665 107.736L111.751 104.121L110.452 103.37L108.366 106.985L109.665 107.736Z"
      fill="#D10911" />
    <path
      d="M84.3222 118.258L90.642 106.774L6.56073 134.494L84.3222 118.258Z"
      fill="#FFB1B3" />
    <path
      d="M6.56073 134.494L90.642 106.774L88.5489 103.159L70.0861 97.8365L6.56073 134.494Z"
      fill="white" />
    <path
      d="M90.642 106.774L91.2995 107.135L91.5034 106.765L91.2914 106.399L90.642 106.774ZM84.3222 118.258L84.4761 118.992L84.8136 118.922L84.9797 118.62L84.3222 118.258ZM6.56073 134.494L6.18525 133.844L6.71456 135.227L6.56073 134.494ZM70.0861 97.8365L70.2937 97.1162L69.987 97.0277L69.7106 97.1872L70.0861 97.8365ZM88.5489 103.159L89.1983 102.784L89.0469 102.522L88.7565 102.438L88.5489 103.159ZM89.9844 106.412L83.6647 117.897L84.9797 118.62L91.2995 107.135L89.9844 106.412ZM84.1684 117.524L6.40691 133.76L6.71456 135.227L84.4761 118.992L84.1684 117.524ZM6.79616 135.206L90.8774 107.486L90.4065 106.062L6.32531 133.782L6.79616 135.206ZM6.93622 135.143L70.4616 98.4857L69.7106 97.1872L6.18525 133.844L6.93622 135.143ZM69.8784 98.5568L88.3412 103.879L88.7565 102.438L70.2937 97.1162L69.8784 98.5568ZM87.8995 103.534L89.9925 107.149L91.2914 106.399L89.1983 102.784L87.8995 103.534Z"
      fill="#D10911" />
    <path
      d="M115.973 100.165C115.982 108.972 108.841 116.113 100.023 116.113C91.204 116.113 84.0495 108.972 84.0408 100.165C84.0322 91.3579 91.1727 84.217 99.9913 84.217C108.81 84.217 115.964 91.3579 115.973 100.165Z"
      fill="#FFB1B3"
      stroke="#D10911"
      stroke-width="1.5" />
    <path
      d="M111.138 100.247C111.144 106.369 106.181 111.334 100.05 111.334C93.9189 111.334 88.9454 106.369 88.9394 100.247C88.9333 94.125 93.8971 89.1606 100.028 89.1606C106.159 89.1606 111.132 94.125 111.138 100.247Z"
      fill="#FFEEEF"
      stroke="#D10911"
      stroke-width="1.5" />
  </svg>
  <p>Map deploying...</p>
</div>
