import { ErrorHandler, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class AppInsightsService {
  private angularPlugin = new AngularPlugin();
  private appInsights = new ApplicationInsights({
    config: {
      disableCookiesUsage: true,
      instrumentationKey: this.configService.config.insights.instrumentationKey,
      extensions: [this.angularPlugin],
      extensionConfig: {
        [this.angularPlugin.identifier]: {
          router: this.router,
          errorServices: [new ErrorHandler()],
        },
      },
    },
  });
  constructor(private configService: ConfigService, private router: Router) {
    if (!this.configService.config.insights.instrumentationKey) {
      return;
    }
    this.appInsights.loadAppInsights();
  }
  trackEvent(name: string): void {
    this.appInsights.trackEvent({ name });
  }
  trackTrace(message: string): void {
    this.appInsights.trackTrace({ message });
  }
}
