<div class="instructions__close">
  <button
    mat-icon-button
    mat-dialog-close
    aria-label="close instructions pop up">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-stepper
  headerPosition="bottom"
  class="instructions__stepper"
  [animationDuration]="'0ms'"
  [selectedIndex]="data.stepIndex"
  appMatVerticalStepperScroller
  #stepper>
  <mat-step *ngFor="let constraint of data.constraints; let i = index">
    <div class="instructions__content">
      <scale-static-instruction
        *ngIf="
          !constraint.constraintName && i < data.constraints.length - 1;
          else selection
        "
        [constraint]="constraint"></scale-static-instruction>
      <ng-template #selection>
        <scale-selection-instruction
          class="instructions__full-width"
          [constraint]="constraint"
          [selectedOption]="
            (selectedConstraintOptions$ | async)![constraint.constraintName!]
          "
          (setConstraint)="setConstraint($event)"></scale-selection-instruction>
      </ng-template>
    </div>
    <div class="instructions__button-container">
      <arc-button
        type="outlined"
        color="secondary"
        class="button--nav"
        (click)="goBack(stepper)"
        *ngIf="constraint.previousStep">
        Back<ng-container *ngIf="!isMobile"
          >: {{ constraint.previousStep }}
        </ng-container>
      </arc-button>
      <arc-button
        class="button--nav"
        *ngIf="
          constraint.closeStep &&
          (!isMobile || constraint.closeStep === 'View Results in Tool')
        "
        (click)="close()">
        <ng-container *ngIf="!isMobile; else mobileCloseText">{{
          constraint.closeStep
        }}</ng-container>
        <ng-template #mobileCloseText
          ><ng-container>View Results</ng-container></ng-template
        >
      </arc-button>
      <arc-button
        (click)="goForward(stepper)"
        cdkFocusInitial
        class="button--nav"
        *ngIf="constraint.nextStep">
        Next<ng-container *ngIf="!isMobile"
          >: {{ constraint.nextStep }}</ng-container
        >
      </arc-button>
    </div>
  </mat-step>
</mat-stepper>
