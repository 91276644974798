import { ConstraintOption } from '@core/models/constraint-option.model';
import { createAction, props } from '@ngrx/store';

export const setConstraintOptions = createAction(
  '[Constraint Controls] Set Constraint Options',
  props<{ constraintOptions: ConstraintOption }>()
);

export const openInstructions = createAction(
  '[Constraint Controls] Open Instructions',
  props<{ stepIndex: number }>()
);

export const openControlPanel = createAction(
  '[Constraint Controls] Open Control Panel'
);
