import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, tap } from 'rxjs';

import * as fromRoot from '@store/index';

import { ConstraintOption } from '@core/models/constraint-option.model';
import { MapLayerConfig } from '@core/models/map-layer-config.model';
import { LayerListActions } from '@store/actions';

@Component({
  selector: 'scale-layer-list',
  templateUrl: './layer-list.component.html',
  styleUrls: ['./layer-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayerListComponent {
  mapLayers$!: Observable<MapLayerConfig[]>;
  scenarioConfig$!: Observable<MapLayerConfig>;
  scenarioLayerId!: string;

  state = false;

  selectedConstraints$: Observable<ConstraintOption> = this.store.select(
    fromRoot.selectLayerListConstraintState
  );

  constructor(private store: Store) {
    this.scenarioConfig$ = this.store
      .select(fromRoot.selectScenarioConfig)
      .pipe(tap(scenarioConfig => (this.scenarioLayerId = scenarioConfig.id)));
    this.mapLayers$ = this.store.select(fromRoot.selectConstraintLayerConfig);
  }

  expand() {
    this.state = this.state ? false : true;
  }

  openInstructions() {
    this.store.dispatch(LayerListActions.openInstructions({ stepIndex: 13 }));
  }

  showLayer(layerId: string) {
    if (layerId === this.scenarioLayerId) {
      this.store.dispatch(LayerListActions.showScenarioLayer());
    } else {
      this.store.dispatch(LayerListActions.showConstraintLayer({ layerId }));
    }
  }

  hideLayer(layerId: string) {
    if (layerId === this.scenarioLayerId) {
      this.store.dispatch(LayerListActions.hideScenarioLayer());
    } else {
      this.store.dispatch(LayerListActions.hideConstraintLayer({ layerId }));
    }
  }
}
