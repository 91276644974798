import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';

import { AppActions, ConstraintActions } from '@store/actions/';

import { ConstraintModel } from '@core/models/constraint.model';

import { DataService } from '@core/services/data.service';
import { mapLayers } from '@core/config/map-config';

@Injectable()
export class ConstraintsEffects {
  loadConstraints$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppActions.enter),
      switchMap(() =>
        this.dataService.loadConstraints().pipe(
          map((constraints: ConstraintModel[]) =>
            ConstraintActions.loadConstraintsSuccess({ constraints })
          ),
          catchError(error =>
            of(ConstraintActions.loadConstraintsFailure({ error }))
          )
        )
      )
    );
  });

  loadConstraintConfig$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppActions.enter),
      map(() =>
        ConstraintActions.loadConstraintConfigSuccess({
          constraintLayerConfig: mapLayers,
        })
      ),
      catchError(error =>
        of(ConstraintActions.loadConstraintConfigFailure({ error }))
      )
    );
  });

  constructor(private actions$: Actions, private dataService: DataService) {}
}
