<div class="menu" [ngClass]="state ? 'menu--open' : 'menu--closed'">
  <button
    disableRipple="true"
    mat-icon-button
    aria-label="Layer List"
    (click)="expand()"
    class="toggle"
    [ngClass]="!state ? 'toggle--open' : 'toggle--closed'">
    <mat-icon *ngIf="!state">menu</mat-icon>
    <mat-icon *ngIf="state">keyboard_double_arrow_left</mat-icon>
  </button>
  <div
    id="items"
    class="items"
    [ngClass]="state ? 'items--open' : 'items--close'">
    <ng-container
      *ngIf="{
        selectedConstraints: selectedConstraints$ | async,
        mapLayers: mapLayers$ | async,
        scenarioConfig: scenarioConfig$ | async
      } as layerData">
      <div class="items-container">
        <h1>SCALE Map Legend</h1>
        <p class="item-type">Foundation Type</p>
        <scale-scenario-layer-list-item
          [layerItem]="layerData.scenarioConfig!"
          (showLayer)="showLayer($event)"
          (hideLayer)="hideLayer($event)">
        </scale-scenario-layer-list-item>
        <scale-seperator></scale-seperator>
        <p class="item-type">LCOE Drivers</p>
        <ng-container *ngFor="let layer of layerData.mapLayers">
          <ng-container *ngIf="layer.layerType === 'LCOE Driver'">
            <scale-layer-list-item
              [layerItem]="layer"
              [layerState]="layerData.selectedConstraints![layer.displayName]"
              (showLayer)="showLayer($event)"
              (hideLayer)="hideLayer($event)"></scale-layer-list-item>
            <scale-seperator></scale-seperator>
          </ng-container>
        </ng-container>
        <p class="item-type">Constraints</p>
        <ng-container *ngFor="let layer of layerData.mapLayers">
          <ng-container *ngIf="layer.layerType === 'Constraint'">
            <scale-layer-list-item
              [layerItem]="layer"
              [layerState]="layerData.selectedConstraints![layer.displayName]"
              (showLayer)="showLayer($event)"
              (hideLayer)="hideLayer($event)"></scale-layer-list-item>
            <scale-seperator></scale-seperator>
          </ng-container>
        </ng-container>
        <p class="item-type">
          For further instructions regarding reading and interpreting the map,
          click the button below.
        </p>
        <div>
          <arc-button
            type="outlined"
            color="secondary"
            (click)="openInstructions()"
            >Reading the map</arc-button
          >
        </div>
      </div>
    </ng-container>
  </div>
</div>
