import { Component, Input } from '@angular/core';
import { ScenarioSummary } from '@core/models/scenario-summary.model';

@Component({
  selector: 'scale-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent {
  @Input() scenarioSummary!: ScenarioSummary;
}
