import { ScenarioSummary } from '@core/models/scenario-summary.model';
import { createAction, props } from '@ngrx/store';

export const loadSummariesSuccess = createAction(
  '[Scenario Summaries] Load Scenario Success',
  props<{ summaries: ScenarioSummary[] }>()
);

export const loadSummariesFailure = createAction(
  '[Scenario Summaries] Load Scenarios Summaries Failure',
  props<{ error: any }>()
);
