<div class="left-panel-container">
  <h1>SCALE Future Offshore Wind</h1>
  <img class="logo" src="assets/arup_logo.svg" alt="Arup" />
  <scale-seperator></scale-seperator>

  <div class="text-container">
    <arc-button
      class="outlined"
      type="outlined"
      color="secondary"
      (click)="openWelcome.emit()"
      >Start Here</arc-button
    >
    <scale-project-summary></scale-project-summary>
  </div>
</div>
