import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ConstraintOption } from '@core/models/constraint-option.model';
import { ConstraintModel } from '@core/models/constraint.model';

@Component({
  selector: 'scale-control-panel',
  templateUrl: './control-panel.component.html',
  styleUrls: ['./control-panel.component.scss'],
})
export class ControlPanelComponent {
  @Input() constraints!: ConstraintModel[];
  @Input() selectedStates?: ConstraintOption;
  @Output() applyChanges: EventEmitter<ConstraintOption> =
    new EventEmitter<ConstraintOption>();

  constructor() {}

  /** set the selected constraint state on component */
  selectConstraint(constraint: string, option: string) {
    this.selectedStates = {
      ...this.selectedStates,
      [constraint]: option,
    };
  }

  filterConstraints(include?: string, exclude?: string) {
    if (include) {
      return this.constraints.filter(c => c.constraintName === include);
    }
    if (exclude) {
      return this.constraints.filter(c => c.constraintName !== exclude);
    }
    return this.constraints;
  }

  /** apply selected constraint states */
  applyConstraintStates() {
    this.applyChanges.emit(this.selectedStates);
  }
}
