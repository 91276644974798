<ng-container
  *ngIf="{
    scenarioData: scenario$ | async,
    scenarioConfig: scenarioConfig$ | async,
    constraintLayerConfig: layerConfig$ | async
  } as allData">
  <scale-layer-list></scale-layer-list>
  <div class="zoom-controls">
    <button
      disableRipple="true"
      mat-icon-button
      aria-label="Reset map extent"
      class="map-button__reset"
      (click)="resetMapView()">
      <mat-icon>zoom_out_map</mat-icon>
    </button>
    <button
      class="map-button"
      disableRipple="true"
      mat-icon-button
      aria-label="Zoom in map"
      (click)="zoomIn()">
      <mat-icon>add</mat-icon>
    </button>
    <button
      class="map-button"
      disableRipple="true"
      mat-icon-button
      aria-label="Zoom out map"
      (click)="zoomOut()">
      <mat-icon>remove</mat-icon>
    </button>
  </div>
  <mgl-map
    [style]="mapConfig.style"
    [zoom]="[mapConfig.zoom]"
    [center]="[mapConfig.center[0], mapConfig.center[1]]"
    (mapLoad)="onMapLoad($event)"
    (data)="updateLayerOrder($event)">
    >

    <!--
      have to wait for spatial data to load before trying to add layers.
      the config layers load faster and try to render before data source
      exists causing mapbox errors
    -->
    <ng-container *ngIf="spatialData$ | async as spatialData">
      <ng-container *ngFor="let layer of spatialData">
        <mgl-geojson-source
          id="{{ layer.name }}-data"
          [data]="layer"></mgl-geojson-source>
      </ng-container>
      <ng-container *ngFor="let layer of allData.constraintLayerConfig">
        <mgl-layer
          [id]="layer.id"
          [type]="layer.type"
          [layout]="layer.layout"
          [paint]="layer.paint"
          [filter]="layer.filter"
          source="{{ layer.id }}-data">
        </mgl-layer>
      </ng-container>
    </ng-container>

    <mgl-geojson-source
      id="{{ allData.scenarioConfig!.id }}-data"
      [data]="allData.scenarioData!"></mgl-geojson-source>
    <mgl-layer
      [id]="allData.scenarioConfig!.id"
      [type]="allData.scenarioConfig!.type"
      [layout]="allData.scenarioConfig!.layout"
      [paint]="allData.scenarioConfig!.paintExpression"
      source="{{ allData.scenarioConfig!.id }}-data">
    </mgl-layer>
  </mgl-map>
</ng-container>
