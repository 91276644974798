<div>
  <p>
    SCALE is a Levelised Cost of Energy (LCOE) and Marine Spatial Planning (MSP)
    tool that is used to support strategic decision making on offshore wind
    deployment. Developed by Arup and used by clients across markets globally,
    this website gives a demo of SCALE.
  </p>
  <p>
    For more details, get in touch at:
    <a href="mailto:scale@arup.com">scale@arup.com</a>
  </p>
</div>
