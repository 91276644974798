<div class="control-panel__container">
  <div class="control-panel__header">
    <h1>Setting Drivers & Constraints</h1>
    <p>Please click apply after selecting changes</p>
  </div>
  <div class="control-panel__constraints">
    <!-- LCOE Drivers -->
    <div
      class="control-panel__row control-panel__row--LCOE selection_buttons"
      *ngFor="let constraint of filterConstraints('LCOE Drivers')">
      <div class="control-panel__title-col">
        {{ constraint.constraintName }}
      </div>
      <button
        mat-flat-button
        *ngFor="let option of constraint.options"
        [ngClass]="
          selectedStates![constraint.constraintName!] === option.name
            ? 'selected'
            : ''
        "
        (click)="selectConstraint(constraint.constraintName!, option.name)">
        {{ option.name }}
        <ng-container *ngIf="constraint.constraintName === 'LCOE Drivers'"
          ><span
            class="description"
            *ngFor="let part of option.description?.split(',')"
            >{{ part }}</span
          >
        </ng-container>
      </button>
    </div>
    <scale-seperator></scale-seperator>
    <!-- Constraints -->
  </div>

  <div class="control-panel__constraints">
    <div
      class="control-panel__row"
      *ngFor="let constraint of filterConstraints(undefined, 'LCOE Drivers')">
      <div class="control-panel__title-col">
        {{ constraint.constraintName }}
      </div>

      <button
        mat-flat-button
        *ngFor="let option of constraint.options"
        [ngClass]="
          selectedStates![constraint.constraintName!] === option.name
            ? 'selected'
            : ''
        "
        (click)="selectConstraint(constraint.constraintName!, option.name)">
        {{ option.name }}
      </button>
    </div>
  </div>
  <div class="control-panel__apply-btn-row">
    <arc-button (click)="applyConstraintStates()">Apply changes</arc-button>
  </div>
</div>
