import { createReducer, on } from '@ngrx/store';
import { CirclePaint, FillPaint } from 'mapbox-gl';

import {
  ConstraintActions,
  ConstraintControls,
  ConstraintControlPanel,
  ConstraintInstructions,
  LayerListActions,
  AppActions,
} from '@store/actions';
import { ConstraintModel } from '@core/models/constraint.model';
import { ConstraintOption } from '@core/models/constraint-option.model';
import { MapLayerConfig } from '@core/models/map-layer-config.model';
import { Theme } from '@core/models/theme.model';

export const constraintFeatureKey = 'constraint';

export interface State {
  constraints: ConstraintModel[];
  selectedConstraintOptions: ConstraintOption;
  lcoeState: ConstraintOption;
  constraintLayerConfig: MapLayerConfig[];
  theme: Theme;
}

export const initialState: State = {
  constraintLayerConfig: [] as any,
  constraints: [],
  selectedConstraintOptions: {},
  lcoeState: {
    Ports: 'Existing',
    Substations: 'Existing',
  },
  theme: 'light',
};

export const reducer = createReducer(
  initialState,
  on(
    ConstraintActions.loadConstraintsSuccess,
    (state, { constraints }): State => {
      /** set default constraint select options */
      const selectedConstraintOptions: { [key: string]: string } = {};
      constraints.forEach((c: ConstraintModel) => {
        if (c.constraintName && c.constraintName !== 'LCOE Drivers') {
          selectedConstraintOptions[c.constraintName] = 'Ignore';
        } else if (c.constraintName && c.constraintName === 'LCOE Drivers') {
          selectedConstraintOptions[c.constraintName] = 'Sensitivity 1';
        }
      });
      return { ...state, constraints, selectedConstraintOptions };
    }
  ),
  on(
    ConstraintControls.setConstraintOptions,
    ConstraintControlPanel.setControlPanelConstraints,
    ConstraintInstructions.setInstructionConstraints,
    (state, { constraintOptions }): State => {
      let lcoeState;
      if (constraintOptions['LCOE Drivers']) {
        lcoeState = setLCOEState(constraintOptions['LCOE Drivers']);
      } else {
        lcoeState = { ...state.lcoeState };
      }
      return {
        ...state,
        selectedConstraintOptions: {
          ...state.selectedConstraintOptions,
          ...constraintOptions,
        },
        lcoeState,
        constraintLayerConfig: updateLayerVisibility(
          state.constraintLayerConfig,
          constraintOptions,
          lcoeState
        ),
      };
    }
  ),
  on(
    ConstraintActions.loadConstraintConfigSuccess,
    (state, { constraintLayerConfig }): State => ({
      ...state,
      constraintLayerConfig,
    })
  ),
  on(LayerListActions.showConstraintLayer, (state, { layerId }): State => {
    const constraintLayerConfig: MapLayerConfig[] =
      state.constraintLayerConfig.map(layer => {
        if (layer.id === layerId) {
          return {
            ...layer,
            layout: {
              visibility: 'visible',
            },
          };
        } else {
          return layer;
        }
      });
    return { ...state, constraintLayerConfig };
  }),
  on(LayerListActions.hideConstraintLayer, (state, { layerId }): State => {
    const constraintLayerConfig: MapLayerConfig[] =
      state.constraintLayerConfig.map(layer => {
        if (layer.id === layerId) {
          return {
            ...layer,
            layout: {
              visibility: 'none',
            },
          };
        } else {
          return layer;
        }
      });
    return { ...state, constraintLayerConfig };
  }),
  on(
    AppActions.updateTheme,
    (state, { theme }): State => ({
      ...state,
      theme,
    })
  )
);

function setLCOEState(driver: string) {
  let portState = 'Existing';
  let substationState = 'Existing';
  /**
      Sensitivity 1: existing ports, existing grid
      Sensitivity 2: future ports, existing grid
      Sensitivity 3: existing ports, future grid
      Sensitivity 4: future ports, future grid
     */
  switch (driver) {
    case 'Sensitivity 2':
      portState = 'Future';
      break;
    case 'Sensitivity 3':
      substationState = 'Future';
      break;
    case 'Sensitivity 4':
      portState = 'Future';
      substationState = 'Future';
      break;
    default:
      break;
  }
  return {
    Ports: portState,
    Substations: substationState,
  };
}

function updateLayerVisibility(
  constraintLayerConfig: MapLayerConfig[],
  constraintOptions: ConstraintOption,
  lcoeState: ConstraintOption
): MapLayerConfig[] {
  const layers = constraintLayerConfig.map(layer => {
    /** set visibility to none if constraint is ignored */
    if (layer.layerType === 'Constraint') {
      const fillPaint = layer.paint as FillPaint;
      if (constraintOptions[layer.displayName] === 'Ignore') {
        return {
          ...layer,
          layout: {
            visibility: 'none',
          },
        };
        /** show layer and set opacity depending on constraint state */
      } else if (constraintOptions[layer.displayName] === 'Hard') {
        return {
          ...layer,
          layout: {
            visibility: 'visible',
          },
          paint: {
            'fill-opacity': 0.3,
            'fill-color': fillPaint['fill-color'],
          },
        };
      } else if (constraintOptions[layer.displayName] === 'Soft') {
        return {
          ...layer,
          layout: {
            visibility: 'visible',
          },
          paint: {
            'fill-opacity': 0.2,
            'fill-color': fillPaint['fill-color'],
          },
        };
      } else {
        return layer;
      }
      /** set LCOE driver visibility and style depending on state */
    } else if (layer.layerType === 'LCOE Driver') {
      const circlePaint = layer.paint as CirclePaint;
      if (layer.displayName === 'Ports') {
        if (lcoeState['Ports'] === 'Existing') {
          return {
            ...layer,
            layout: {
              visibility: 'visible',
            },
            paint: {
              'circle-radius': circlePaint['circle-radius'],
              'circle-color': circlePaint['circle-color']
                ? circlePaint['circle-color']
                : circlePaint['circle-stroke-color'],
            },
            filter: [layer.filter[0], layer.filter[1], lcoeState['Ports']],
          };
        }
        if (lcoeState['Ports'] === 'Future') {
          return {
            ...layer,
            layout: {
              visibility: 'visible',
            },
            paint: {
              'circle-radius': circlePaint['circle-radius'],
              'circle-opacity': 0,
              'circle-stroke-width': 1,
              'circle-stroke-color': circlePaint['circle-color']
                ? circlePaint['circle-color']
                : circlePaint['circle-stroke-color'],
            },
            filter: [layer.filter[0], layer.filter[1], lcoeState['Ports']],
          };
        }
      }
      if (layer.displayName === 'Substations') {
        if (lcoeState['Substations'] === 'Existing') {
          return {
            ...layer,
            layout: {
              visibility: 'visible',
            },
            paint: {
              'circle-radius': circlePaint['circle-radius'],
              'circle-color': circlePaint['circle-color']
                ? circlePaint['circle-color']
                : circlePaint['circle-stroke-color'],
            },
            filter: [
              layer.filter[0],
              layer.filter[1],
              lcoeState['Substations'],
            ],
          };
        }
        if (lcoeState['Substations'] === 'Future') {
          return {
            ...layer,
            layout: {
              visibility: 'visible',
            },
            paint: {
              'circle-radius': circlePaint['circle-radius'],
              'circle-opacity': 0,
              'circle-stroke-width': 1,
              'circle-stroke-color': circlePaint['circle-color']
                ? circlePaint['circle-color']
                : circlePaint['circle-stroke-color'],
            },
            filter: [
              layer.filter[0],
              layer.filter[1],
              lcoeState['Substations'],
            ],
          };
        }
      }
      return layer;
    } else {
      return layer;
    }
  });
  return layers as MapLayerConfig[];
}

export const getConstraints = (state: State) => state.constraints;
export const getConstraintLayerConfig = (state: State) =>
  state.constraintLayerConfig;
export const getSelectedConstraintOptions = (state: State) =>
  state.selectedConstraintOptions;
export const getLCOEState = (state: State) => state.lcoeState;
export const getTheme = (state: State) => state.theme;
