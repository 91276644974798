<arc-container #container theme="light" fullscreen="true">
  <arc-navbar #navbar home="/" slot="nav" logo="assets/scale-logo.png">
    <span slot="name">{{ title }}</span>
  </arc-navbar>
  <main>
    <ng-container *ngIf="!isMobile">
      <div
        id="title-sidebar"
        class="title-sidebar"
        [ngClass]="
          drawer.opened ? 'title-sidebar--closed' : 'title-sidebar--open'
        "></div>
      <button
        mat-flat-button
        class="toggle-sidebar-button"
        [ngClass]="
          drawer.opened
            ? 'toggle-sidebar-button--open'
            : 'toggle-sidebar-button--closed'
        "
        (click)="drawer.toggle()"
        aria-label="Open informational side panel">
        <mat-icon>keyboard_double_arrow_right</mat-icon>
      </button>
    </ng-container>
    <mat-drawer-container [hasBackdrop]="false">
      <mat-drawer #drawer class="sidenav" mode="side">
        <div class="sidenav__container">
          <scale-left-panel (openWelcome)="openWelcome()"></scale-left-panel>
        </div>
      </mat-drawer>
      <div class="content">
        <section class="content__summary" *ngIf="isMobile">
          <div class="summary-title">
            <h1>SCALE</h1>
            <arc-button
              class="outlined"
              type="outlined"
              color="accent"
              (click)="openWelcome()">
              Start Here
            </arc-button>
          </div>
          <scale-seperator></scale-seperator>
          <scale-project-summary></scale-project-summary>
        </section>
        <div class="content__controls-container">
          <scale-controls></scale-controls>
          <ng-container *ngIf="scenarioSummary$ | async as summary">
            <scale-summary [scenarioSummary]="summary"></scale-summary>
          </ng-container>
        </div>
        <div class="content__map-container">
          <scale-loading-spinner
            *ngIf="mapDeploying$ | async"></scale-loading-spinner>
          <scale-map *ngIf="mapDeployed$ | async"></scale-map>
        </div>
      </div>
    </mat-drawer-container>
  </main>
</arc-container>
