import { ConstraintModel } from '@core/models/constraint.model';
import { MapLayerConfig } from '@core/models/map-layer-config.model';
import { createAction, props } from '@ngrx/store';

export const loadConstraints = createAction('[Constraint] Load Constraints');

export const loadConstraintConfigSuccess = createAction(
  '[Constraint] Load Constraint Config Success',
  props<{ constraintLayerConfig: MapLayerConfig[] }>()
);

export const loadConstraintsSuccess = createAction(
  '[Constraint] Load Constraints Success',
  props<{ constraints: ConstraintModel[] }>()
);

export const loadConstraintsFailure = createAction(
  '[Constraint] Load Constraints Failure',
  props<{ error: any }>()
);

export const loadConstraintConfigFailure = createAction(
  '[Constraint] Load Constraint Config Failure',
  props<{ error: any }>()
);
