import { Component, OnInit } from '@angular/core';
import { AnyPaint, CirclePaint, Expression } from 'mapbox-gl';
import { LayerListItemComponent } from '../layer-list-item/layer-list-item.component';

@Component({
  selector: 'scale-scenario-layer-list-item',
  templateUrl: './scenario-layer-list-item.component.html',
  styleUrls: ['./scenario-layer-list-item.component.scss'],
})
export class ScenarioLayerListItemComponent
  extends LayerListItemComponent
  implements OnInit
{
  constructor() {
    super();
  }
  foundationTypes?: string[];
  colors?: string[];

  override ngOnInit() {
    const expression = this.layerItem
      .paintExpression as AnyPaint as CirclePaint;
    const circleColour = expression['circle-color'] as Expression;
    this.colors = [circleColour[3], circleColour[5], circleColour[7]];
    this.foundationTypes = [circleColour[2], circleColour[4], circleColour[6]];
  }
}
