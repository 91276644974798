import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '@material/material.module';

import { NgxMapboxGLModule } from 'ngx-mapbox-gl';

import { CoreModule } from '@core/core.module';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { metaReducers, ROOT_REDUCERS } from './store';
import {
  ConstraintsEffects,
  DialogEffects,
  ScenarioEffects,
} from './store/effects';

import '@arc-web/components/dist/arc';
import { environment } from 'src/environments/environment';

import { AppComponent } from './app.component';
import { ConstraintComponent } from './components/constraint/constraint.component';
import { ControlsComponent } from './containers/controls/controls.component';
import { ControlPanelComponent } from './components/control-panel/control-panel.component';
import { ControlPanelDialogComponent } from './containers/control-panel-dialog/control-panel-dialog.component';
import { InstructionDialogComponent } from './containers/instruction-dialog/instruction-dialog.component';
import { LayerListComponent } from './containers/layer-list/layer-list.component';
import { LayerListItemComponent } from './components/layer-list-item/layer-list-item.component';
import { LeftPanelComponent } from './components/left-panel/left-panel.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { MapComponent } from './containers/map/map.component';
import { ScenarioLayerListItemComponent } from './components/scenario-layer-list-item/scenario-layer-list-item.component';
import { SelectionInstructionComponent } from './components/selection-instruction/selection-instruction.component';
import { SeperatorComponent } from './components/seperator/seperator.component';
import { StaticInstructionComponent } from './components/static-instruction/static-instruction.component';
import { SummaryComponent } from './components/summary/summary.component';
import { ProjectSummaryComponent } from './components/project-summary/project-summary.component';
import { ScrollToTopDirective } from './containers/instruction-dialog/scroll-to-top.directive';

@NgModule({
  declarations: [
    AppComponent,
    ConstraintComponent,
    ControlsComponent,
    ControlPanelComponent,
    ControlPanelDialogComponent,
    InstructionDialogComponent,
    LayerListComponent,
    LayerListItemComponent,
    LeftPanelComponent,
    LoadingSpinnerComponent,
    MapComponent,
    ScenarioLayerListItemComponent,
    SelectionInstructionComponent,
    SeperatorComponent,
    StaticInstructionComponent,
    SummaryComponent,
    ProjectSummaryComponent,
    ScrollToTopDirective,
  ],
  imports: [
    CoreModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    NgxMapboxGLModule.withConfig({
      accessToken: environment.mapbox.apiKey,
    }),
    StoreModule.forRoot(ROOT_REDUCERS, {
      metaReducers,
      runtimeChecks: {
        strictStateSerializability: true,
        strictActionSerializability: true,
        strictActionWithinNgZone: true,
        strictActionTypeUniqueness: true,
      },
    }),
    !environment.production
      ? StoreDevtoolsModule.instrument({
          name: 'SCALE',
          maxAge: 25,
          logOnly: environment.production,
        })
      : [],
    EffectsModule.forRoot([ConstraintsEffects, DialogEffects, ScenarioEffects]),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
