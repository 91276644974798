import { createAction, props } from '@ngrx/store';

export const openInstructions = createAction(
  '[Layer List] Open Map Instructions',
  props<{ stepIndex: number }>()
);

export const showScenarioLayer = createAction(
  '[Layer List] Show Scenario Layer'
);

export const hideScenarioLayer = createAction(
  '[Layer List] Hide Scenario Layer'
);

export const showConstraintLayer = createAction(
  '[Layer List] Show Constraint Layer',
  props<{ layerId: string }>()
);

export const hideConstraintLayer = createAction(
  '[Layer List] Hide Constraint Layer',
  props<{ layerId: string }>()
);
