<div class="item-container">
  <div class="scenario-layer-swatch">
    <div
      *ngFor="let color of colors"
      [style.background-color]="color"
      class="scenario-icon"></div>
  </div>
  <div class="label-col">
    <p *ngFor="let foundation of foundationTypes">
      {{ foundation }}
    </p>
  </div>
  <div class="checkbox-col">
    <mat-checkbox
      labelPosition="before"
      [checked]="layerItem.layout.visibility === 'visible' ? true : false"
      (change)="setLayerVisibility($event)"
      >on/off</mat-checkbox
    >
  </div>
</div>
