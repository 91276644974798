import { Component, Input } from '@angular/core';
import { ConstraintModel } from '@core/models/constraint.model';

@Component({
  selector: 'scale-static-instruction',
  templateUrl: './static-instruction.component.html',
  styleUrls: ['./static-instruction.component.scss'],
})
export class StaticInstructionComponent {
  @Input() constraint!: ConstraintModel;

  constructor() {}
}
