import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';

import { MapLayerConfig } from '@core/models/map-layer-config.model';
import { AnyPaint, CirclePaint, FillPaint } from 'mapbox-gl';

@Component({
  selector: 'scale-layer-list-item',
  templateUrl: './layer-list-item.component.html',
  styleUrls: ['./layer-list-item.component.scss'],
})
export class LayerListItemComponent implements OnInit, OnChanges {
  @Input() layerItem!: MapLayerConfig;
  @Input() layerState!: string;
  selected?: boolean;
  @Output() showLayer: EventEmitter<string> = new EventEmitter<string>();
  @Output() hideLayer: EventEmitter<string> = new EventEmitter<string>();

  color!: string;
  backgroundColor!: string;
  opacity!: number;
  borderColor!: string;
  borderWidth = '0px';
  borderRadius = '0px';
  borderStyle = 'solid';
  height = '40px';

  constructor() {}

  ngOnInit(): void {
    this.setLayerSwatch();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.layerItem) {
      if (changes.layerItem.currentValue.layout.visibility === 'visible') {
        this.selected = true;
      } else {
        this.selected = false;
      }

      if (this.layerItem.layerType === 'LCOE Driver') {
        this.updateLCOESwatches();
      }
    }
    if (changes.layerState) {
      this.layerStateChange();
    }
  }

  setLayerVisibility(checkboxChange: MatCheckboxChange) {
    if (checkboxChange.checked) {
      this.showLayer.emit(this.layerItem.id);
    }
    if (!checkboxChange.checked) {
      this.hideLayer.emit(this.layerItem.id);
    }
  }

  setLayerSwatch() {
    if (!this.layerItem) return;
    /** set properties for the different layer swatchs */
    const paint: AnyPaint | undefined = this.layerItem.paint;
    /** LCOE layers */
    if (
      this.layerItem.type === 'circle' &&
      this.layerItem.layerType !== 'Scenario'
    ) {
      const circlePaint = paint as CirclePaint;
      this.height = '24px';
      this.borderRadius = '50px';
      if (circlePaint['circle-opacity'] === 0) {
        this.borderColor = circlePaint['circle-stroke-color'] as string;
        this.borderWidth = '1px';
      } else {
        this.color = circlePaint['circle-color'] as string;
        this.backgroundColor = this.color;
      }
    }

    if (this.layerItem.type === 'fill') {
      const fillPaint = paint as FillPaint;
      this.color = fillPaint['fill-color'] as string;
      this.backgroundColor = this.color;
    }
  }

  /**
   * change swatch opacity if layer state changes
   */
  layerStateChange() {
    const paint: AnyPaint | undefined = this.layerItem.paint;
    const fillPaint = paint as FillPaint;
    if (this.layerState === 'Hard') {
      this.opacity = 0.3;
    } else {
      this.opacity = fillPaint['fill-opacity'] as number;
    }
  }

  updateLCOESwatches() {
    const paint: AnyPaint | undefined = this.layerItem.paint;
    const circlePaint = paint as CirclePaint;
    if (this.layerState === 'Future') {
      this.borderColor = circlePaint['circle-color'] as string;
      this.borderWidth = '1px';
      this.backgroundColor = '#fff';
    }

    if (this.layerState === 'Existing') {
      this.color = circlePaint['circle-color'] as string;
      this.backgroundColor = this.color;
    }
  }
}
