import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
  Renderer2,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ArcContainer } from '@arc-web/components';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Store } from '@ngrx/store';
import { Observable, tap } from 'rxjs';

import posthog from 'posthog-js';

import * as fromRoot from '@store/index';

import { AppActions, LeftPanelActions } from './store/actions';
import { ScenarioSummary } from '@core/models/scenario-summary.model';
import { Theme } from '@core/models/theme.model';
import { ConfigService } from '@core/services/config.service';

@Component({
  selector: 'scale-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'SCALE';
  isMobile: boolean = false;
  @ViewChild('container') container!: ElementRef<ArcContainer>;

  mapDeploying$!: Observable<boolean>;
  mapDeployed$!: Observable<boolean>;
  scenarioSummary$!: Observable<ScenarioSummary | undefined>;

  constructor(
    private configService: ConfigService,
    private store: Store,
    private breakpointObserver: BreakpointObserver,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2
  ) {
    breakpointObserver.observe([Breakpoints.Handset]).subscribe(result => {
      this.isMobile = result.matches;
    });
  }

  ngOnInit(): void {
    this.store.dispatch(AppActions.enter());
    this.mapDeploying$ = this.store.select(fromRoot.selectScenarioLoading);
    this.mapDeployed$ = this.store.select(fromRoot.selectScenarioLoaded);

    // setup posthog when application is in production
    if (this.configService.config.production) {
      posthog.init(`${this.configService.config.posthogId}`, {
        api_host: 'https://posthog.insights.arup.com',
        persistence: 'memory',
      });
    }
    this.scenarioSummary$ = this.store.select(fromRoot.selectScenarioSummary);
  }

  ngAfterViewInit(): void {
    this.container.nativeElement.addEventListener(
      'arc-accessibility-change',
      this.accessibilityChanged.bind(this)
    );
  }

  accessibilityChanged(event: any) {
    const theme = event.detail.preferences.theme;
    if (theme === 'auto')
      this.setTheme(
        window.matchMedia &&
          window.matchMedia('(prefers-color-scheme: light)').matches
          ? 'light'
          : 'dark'
      );
    else this.setTheme(theme);
  }

  setTheme(theme: Theme) {
    this.renderer.setAttribute(this.document.body, 'theme', theme);
    this.renderer.addClass(this.document.body, theme);
    this.renderer.removeClass(
      this.document.body,
      theme === 'light' ? 'mgt-dark' : 'mgt-light'
    );
    this.renderer.addClass(
      this.document.body,
      theme === 'light' ? 'mgt-light' : 'mgt-dark'
    );
    this.container.nativeElement.setAttribute('theme', theme);
    this.store.dispatch(AppActions.updateTheme({ theme }));
  }

  openWelcome(): void {
    this.store.dispatch(LeftPanelActions.openWelcome({ stepIndex: 0 }));
  }
}
