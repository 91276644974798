import { MapLayerConfig } from '@core/models/map-layer-config.model';
import { ScenarioSummary } from '@core/models/scenario-summary.model';
import { ScenarioModel } from '@core/models/scenario.model';
import { createReducer, on } from '@ngrx/store';
import {
  AppActions,
  ConstraintControlPanel,
  ConstraintControls,
  ConstraintInstructions,
  LayerListActions,
  ScenarioActions,
  ScenarioSummariesActions,
} from '@store/actions';

export const scenarioFeatureKey = 'scenario';

export interface State {
  scenarioConfig: MapLayerConfig;
  filtering: boolean;
  scenario: ScenarioModel[];
  scenarioId: string;
  summaries: ScenarioSummary[];
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  scenarioConfig: undefined as any,
  filtering: false,
  scenario: [],
  scenarioId: '201',
  loaded: false,
  loading: false,
  summaries: [],
};

export const reducer = createReducer(
  initialState,
  on(
    AppActions.enter,
    (state): State => ({
      ...state,
      loading: true,
    })
  ),
  on(
    ScenarioActions.loadScenariosSuccess,
    (state): State => ({
      ...state,
      loading: false,
      loaded: true,
    })
  ),
  on(
    ConstraintControls.setConstraintOptions,
    ConstraintControlPanel.setControlPanelConstraints,
    ConstraintInstructions.setInstructionConstraints,
    (state): State => ({
      ...state,
      filtering: true,
    })
  ),
  on(
    ScenarioActions.filterScenarios,
    (state, { scenario }): State => ({
      ...state,
      scenario,
      scenarioId: scenario[0].id,
      filtering: false,
    })
  ),
  on(
    ScenarioActions.loadScenarioConfigSuccess,
    (state, { scenarioConfig }): State => ({
      ...state,
      scenarioConfig,
    })
  ),
  on(
    ConstraintControls.setConstraintOptions,
    LayerListActions.showScenarioLayer,
    (state): State => ({
      ...state,
      scenarioConfig: {
        ...state.scenarioConfig,
        layout: {
          visibility: 'visible',
        },
      },
    })
  ),
  on(
    LayerListActions.hideScenarioLayer,
    (state): State => ({
      ...state,
      scenarioConfig: {
        ...state.scenarioConfig,
        layout: {
          visibility: 'none',
        },
      },
    })
  ),
  on(
    ScenarioSummariesActions.loadSummariesSuccess,
    (state, { summaries }): State => ({
      ...state,
      summaries,
    })
  )
);

export const getScenario = (state: State) => state.scenario;
export const getScenarioConfig = (state: State) => state.scenarioConfig;
export const getScenarioId = (state: State) => state.scenarioId;
export const getScenarioSummaries = (state: State) => state.summaries;
export const getScenarioLoaded = (state: State) => state.loaded;
export const getScenarioLoading = (state: State) => state.loading;
export const getScenarioFiltering = (state: State) => state.filtering;
