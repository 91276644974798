import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { FeatureCollection } from 'geojson';
import { map, Observable, Subscription, tap } from 'rxjs';
import { Map } from 'mapbox-gl';

import * as fromRoot from '@store/index';

import { MapConfig } from '@core/models/map-config.model';
import { MapLayerConfig } from '@core/models/map-layer-config.model';
import { ScenarioModel } from '@core/models/scenario.model';

import { DataService } from '@core/services/data.service';
import { mapConfig } from '@core/config/map-config';

@Component({
  selector: 'scale-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapComponent implements OnDestroy {
  map!: Map;
  scenario$: Observable<FeatureCollection>;
  scenarioConfig$!: Observable<MapLayerConfig>;
  scenarioId!: string;
  mapConfig!: MapConfig;
  layerConfig$!: Observable<MapLayerConfig[]>;
  layerConfig!: MapLayerConfig[];
  spatialData$: Observable<any[]> = this.dataService.loadSpatialData();
  theme?: Subscription;

  constructor(private dataService: DataService, private store: Store) {
    this.scenarioConfig$ = this.store
      .select(fromRoot.selectScenarioConfig)
      .pipe(
        tap(
          (scenarioConfig: MapLayerConfig) =>
            (this.scenarioId = scenarioConfig.id)
        )
      );
    this.mapConfig = mapConfig;
    this.layerConfig$ = this.store
      .select(fromRoot.selectConstraintLayerConfig)
      .pipe(
        tap(
          (constraintLayerConfig: MapLayerConfig[]) =>
            (this.layerConfig = constraintLayerConfig)
        )
      );
    this.scenario$ = this.store
      .select(fromRoot.selectScenario)
      .pipe(
        map((scenario: ScenarioModel[]) => this.dataService.toGeoJSON(scenario))
      );
  }

  onMapLoad(mapInstance: Map) {
    this.map = mapInstance;

    this.theme = this.store.select(fromRoot.selectTheme).subscribe(theme => {
      const darkStyle = this.mapConfig.style.replace('light', 'dark');
      let style = theme === 'dark' ? darkStyle : this.mapConfig.style;
      this.map.setStyle(style);
    });
  }

  // move scenario points to top after they are loaded
  updateLayerOrder(e: any) {
    if (
      e.sourceId === `${this.scenarioId}-data` &&
      e.sourceDataType === 'content'
    ) {
      this.map.moveLayer(this.scenarioId);
    }
  }

  ngOnDestroy(): void {
    this.theme?.unsubscribe();
  }

  resetMapView() {
    this.map.easeTo({
      center: [this.mapConfig.center[0], this.mapConfig.center[1]],
      zoom: this.mapConfig.zoom,
      duration: 1500,
    });
  }

  zoomIn() {
    this.map.zoomIn();
  }

  zoomOut() {
    this.map.zoomOut();
  }
}
