import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ConstraintModel } from '@core/models/constraint.model';
import { ConstraintOption } from '@core/models/constraint-option.model';

@Component({
  selector: 'scale-selection-instruction',
  templateUrl: './selection-instruction.component.html',
  styleUrls: ['./selection-instruction.component.scss'],
})
export class SelectionInstructionComponent {
  @Input() constraint!: ConstraintModel;
  @Input() selectedOption!: string;
  @Output() setConstraint: EventEmitter<ConstraintOption> =
    new EventEmitter<ConstraintOption>();
  constructor() {}

  selectConstraint(option: string) {
    this.selectedOption = option;
    this.setConstraint.emit({
      [this.constraint.constraintName as string]: option,
    });
  }
}
