import { MapConfig } from '@core/models/map-config.model';
import { MapLayerConfig } from '@core/models/map-layer-config.model';

export const mapConfig: MapConfig = {
  style: 'mapbox://styles/mapbox/light-v9',
  zoom: 5.8,
  center: [120.9721, 14.5961],
};
export const scenarioSummaries = {
  filename: 'summaries.csv',
};
export const scenarioConfig: MapLayerConfig = {
  layerType: 'Scenario',
  filename: 'dispatch_model_cop27.csv',
  displayName: 'Foundation Type',
  mapLayerIndex: 6,
  id: 'scenario-points',
  type: 'circle',
  layout: {
    visibility: 'visible',
  },
  paintExpression: {
    'circle-radius': ['interpolate', ['linear'], ['zoom'], 5, 2, 10, 6],
    'circle-color': [
      'match',
      ['get', 'foundation'],
      'Jacket',
      '#223b53',
      'Monopile',
      '#CE3030',
      'Floating',
      '#E676FF',
      '#ccc',
    ],
  },
};
export const mapLayers: MapLayerConfig[] = [
  {
    layerType: 'LCOE Driver',
    displayName: 'Ports',
    filename: 'construction_ports.geojson',
    mapLayerIndex: 0,
    id: 'port-points',
    type: 'circle',
    layout: {
      visibility: 'visible',
    },
    paint: {
      'circle-radius': 4,
      'circle-color': '#43B3EA',
    },
    filter: ['==', ['get', 'Type'], 'Existing'],
  },
  {
    layerType: 'LCOE Driver',
    displayName: 'Substations',
    filename: 'substations.geojson',
    mapLayerIndex: 1,
    id: 'substation-points',
    type: 'circle',
    layout: {
      visibility: 'visible',
    },
    paint: {
      'circle-radius': 4,
      'circle-color': '#F59148',
    },
    filter: ['==', ['get', 'Type'], 'Existing'],
  },
  {
    layerType: 'Constraint',
    displayName: 'Coastal Buffer',
    filename: 'coastal_buffer.geojson',
    mapLayerIndex: 2,
    id: 'coastal-buffer-areas',
    type: 'fill',
    layout: {
      visibility: 'none',
    },
    paint: {
      'fill-color': '#BD8400',
      'fill-opacity': 0.2,
    },
  },
  {
    layerType: 'Constraint',
    displayName: 'Environmental Designations',
    filename: 'combined_env_constraints.geojson',
    mapLayerIndex: 3,
    id: 'environmental-areas',
    type: 'fill',
    layout: {
      visibility: 'none',
    },
    paint: {
      'fill-color': '#4BA046',
      'fill-opacity': 0.2,
    },
  },
  {
    layerType: 'Constraint',
    displayName: 'Fishing',
    filename: 'fishing_areas.geojson',
    mapLayerIndex: 4,
    id: 'fishing-areas',
    type: 'fill',
    layout: {
      visibility: 'none',
    },
    paint: {
      'fill-color': '#32A4A0',
      'fill-opacity': 0.2,
    },
  },
  {
    layerType: 'Constraint',
    displayName: 'Shipping',
    filename: 'high_density_shipping.geojson',
    mapLayerIndex: 5,
    id: 'shipping-areas',
    type: 'fill',
    layout: {
      visibility: 'none',
    },
    paint: {
      'fill-color': '#7D4196',
      'fill-opacity': 0.2,
    },
  },
];
