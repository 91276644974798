import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, of } from 'rxjs';
import { Store } from '@ngrx/store';

import {
  ConstraintControls,
  ConstraintControlPanel,
  ConstraintInstructions,
  ScenarioActions,
  AppActions,
  ScenarioSummariesActions,
} from '@store/actions';

import * as fromRoot from '@store/index';

import { DataService } from '@core/services/data.service';

import { ScenarioModel } from '@core/models/scenario.model';
import { ScenarioSummary } from '@core/models/scenario-summary.model';
import { scenarioConfig } from '@core/config/map-config';

@Injectable()
export class ScenarioEffects {
  /**
   * Load scenario data when app loads
   */
  loadScenarios$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppActions.enter),
      switchMap(() =>
        this.dataService.loadScenarios().pipe(
          map((scenarios: ScenarioModel[]) =>
            ScenarioActions.loadScenariosSuccess()
          ),
          catchError(error =>
            of(ScenarioActions.loadScenariosFailure({ error }))
          )
        )
      )
    );
  });

  /**
   * Load scenario summaries when app loads
   */
  loadSummaries$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppActions.enter),
      switchMap(() =>
        this.dataService.loadScenarioDescriptions().pipe(
          map((summaries: ScenarioSummary[]) =>
            ScenarioSummariesActions.loadSummariesSuccess({ summaries })
          ),
          catchError(error =>
            of(ScenarioSummariesActions.loadSummariesFailure({ error }))
          )
        )
      )
    );
  });

  /**
   * filter scenarios and load into state
   */
  selectScenarios$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ConstraintControls.setConstraintOptions,
        ConstraintControlPanel.setControlPanelConstraints,
        ScenarioActions.loadScenariosSuccess,
        ConstraintInstructions.setInstructionConstraints
      ),
      concatLatestFrom(() => [
        this.store.select(fromRoot.selectConstraintOptions),
        this.store.select(fromRoot.selectLCOEState),
      ]),
      switchMap(([action, selectedConstraints, lcoeState]) =>
        this.dataService.filterScenarios(selectedConstraints, lcoeState).pipe(
          map((scenario: ScenarioModel[]) =>
            ScenarioActions.filterScenarios({ scenario })
          ),
          catchError(error =>
            of(ScenarioActions.loadScenariosFailure({ error }))
          )
        )
      )
    );
  });

  loadScenarioConfig$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppActions.enter),
      map(() =>
        ScenarioActions.loadScenarioConfigSuccess({
          scenarioConfig: scenarioConfig,
        })
      ),
      catchError(error =>
        of(ScenarioActions.loadScenarioConfigFailure({ error }))
      )
    );
  });

  constructor(
    private actions$: Actions,
    private dataService: DataService,
    private store: Store
  ) {}
}
