import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { ConstraintOption } from '@core/models/constraint-option.model';
import { ConstraintModel } from '@core/models/constraint.model';

import * as fromRoot from '@store/index';
import { ConstraintControlPanel } from '@store/actions';

@Component({
  selector: 'scale-control-panel-dialog',
  templateUrl: './control-panel-dialog.component.html',
  styleUrls: ['./control-panel-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlPanelDialogComponent {
  selectedConstraintOptions$: Observable<ConstraintOption>;
  constructor(
    @Inject(MAT_DIALOG_DATA) public constraints: ConstraintModel[],
    public dialogRef: MatDialogRef<ControlPanelDialogComponent>,
    private store: Store
  ) {
    this.constraints = this.constraints.filter(c => c.constraintName);
    this.selectedConstraintOptions$ = this.store.select(
      fromRoot.selectConstraintOptions
    );
  }

  close(): void {
    this.dialogRef.close();
  }

  applyChanges($event: ConstraintOption): void {
    this.store.dispatch(
      ConstraintControlPanel.setControlPanelConstraints({
        constraintOptions: $event,
      })
    );
    this.dialogRef.close();
  }
}
