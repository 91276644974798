import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';

import { AppInsightsService } from './services/app-insights.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    AppInsightsService,
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService,
    },
  ],
})
export class CoreModule {
  constructor(private insights: AppInsightsService) {}
}
