import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromRoot from '@store/index';
import { ConstraintInstructions } from '@store/actions';
import { ConstraintModel } from '@core/models/constraint.model';
import { ConstraintOption } from '@core/models/constraint-option.model';

@Component({
  selector: 'scale-instruction-dialog',
  templateUrl: './instruction-dialog.component.html',
  styleUrls: ['./instruction-dialog.component.scss'],
})
export class InstructionDialogComponent {
  selectedConstraintOptions$: Observable<ConstraintOption>;
  isMobile: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { constraints: ConstraintModel[]; stepIndex: number },
    public dialogRef: MatDialogRef<InstructionDialogComponent>,
    private store: Store,
    breakpointObserver: BreakpointObserver
  ) {
    this.selectedConstraintOptions$ = this.store.select(
      fromRoot.selectConstraintOptions
    );
    breakpointObserver.observe([Breakpoints.Handset]).subscribe(result => {
      this.isMobile = result.matches;
    });
  }

  goForward(stepper: MatStepper): void {
    stepper.next();
  }

  goBack(stepper: MatStepper): void {
    stepper.previous();
  }

  close(): void {
    this.dialogRef.close();
  }

  setConstraint(constraint: ConstraintOption): void {
    this.store.dispatch(
      ConstraintInstructions.setInstructionConstraints({
        constraintOptions: constraint,
      })
    );
  }
}
