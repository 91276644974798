<div class="control-panel__close">
  <button
    mat-icon-button
    mat-dialog-close
    aria-label="close control panel pop up">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="control-panel__content">
  <ng-container
    *ngIf="selectedConstraintOptions$ | async as selectedConstraintOptions">
    <scale-control-panel
      [selectedStates]="selectedConstraintOptions"
      [constraints]="constraints"
      (applyChanges)="applyChanges($event)"
      class="control-panel__full-width"></scale-control-panel>
  </ng-container>
</div>
