import { InjectionToken } from '@angular/core';
import {
  Action,
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer,
} from '@ngrx/store';

import { ConstraintModel } from '@core/models/constraint.model';
import { MapLayerConfig } from '@core/models/map-layer-config.model';

import * as fromConstraints from '@store/reducers/constraint.reducer';
import * as fromScenarios from '@store/reducers/scenario.reducer';

import { environment } from '../../environments/environment';

export interface State {
  [fromConstraints.constraintFeatureKey]: fromConstraints.State;
  [fromScenarios.scenarioFeatureKey]: fromScenarios.State;
}

export const ROOT_REDUCERS = new InjectionToken<
  ActionReducerMap<State, Action>
>('Root reducers token', {
  factory: () => ({
    [fromConstraints.constraintFeatureKey]: fromConstraints.reducer,
    [fromScenarios.scenarioFeatureKey]: fromScenarios.reducer,
  }),
});

export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state, action) => {
    const result = reducer(state, action);
    console.groupCollapsed(action.type);
    console.log('prev state', state);
    console.log('action', action);
    console.log('next state', result);
    console.groupEnd();

    return result;
  };
}
export const metaReducers: MetaReducer<State>[] = !environment.production
  ? [logger]
  : [];

/**
 * Constraint Selectors
 */
export const selectConstraintState =
  createFeatureSelector<fromConstraints.State>(
    fromConstraints.constraintFeatureKey
  );

export const selectAllConstraints = createSelector(
  selectConstraintState,
  fromConstraints.getConstraints
);

/** selected constraint options */
export const selectConstraintOptions = createSelector(
  selectConstraintState,
  fromConstraints.getSelectedConstraintOptions
);

/** selected constraint options to populate dropdowns or modal */
export const selectConstraints = createSelector(
  selectAllConstraints,
  (constraints: ConstraintModel[]) => {
    return constraints.filter((c: ConstraintModel) => c.options);
  }
);

/** get the state (future or existing) values for LCOE drivers*/
export const selectLCOEState = createSelector(
  selectConstraintState,
  fromConstraints.getLCOEState
);

export const selectLayerListConstraintState = createSelector(
  selectConstraintOptions,
  selectLCOEState,
  (allConstraints, lcoeContraints) => {
    return {
      ...allConstraints,
      ...lcoeContraints,
    };
  }
);

export const selectConstraintLayerConfig = createSelector(
  selectConstraintState,
  fromConstraints.getConstraintLayerConfig
);

export const selectTheme = createSelector(
  selectConstraintState,
  fromConstraints.getTheme
);

/** Scenario Selectors */
export const selectScenarioState = createFeatureSelector<fromScenarios.State>(
  fromScenarios.scenarioFeatureKey
);

export const selectScenario = createSelector(
  selectScenarioState,
  fromScenarios.getScenario
);

export const selectScenarioLoading = createSelector(
  selectScenarioState,
  fromScenarios.getScenarioLoading
);

export const selectScenarioLoaded = createSelector(
  selectScenarioState,
  fromScenarios.getScenarioLoaded
);

export const selectScenarioFiltering = createSelector(
  selectScenarioState,
  fromScenarios.getScenarioFiltering
);

export const selectScenarioConfig = createSelector(
  selectScenarioState,
  fromScenarios.getScenarioConfig
);

export const selectScenarioLayerLayout = createSelector(
  selectScenarioConfig,
  (scenarioConfig: MapLayerConfig) => scenarioConfig.layout
);

export const selectScenarioId = createSelector(
  selectScenarioState,
  fromScenarios.getScenarioId
);

/** all summaries */
export const selectScenarioSummaries = createSelector(
  selectScenarioState,
  fromScenarios.getScenarioSummaries
);

export const selectScenarioSummary = createSelector(
  selectScenarioId,
  selectScenarioSummaries,
  (scenarioId, summaries) =>
    summaries.find(summary => summary.scenarioId === scenarioId)
);
