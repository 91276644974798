import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  catchError,
  Observable,
  map,
  throwError,
  tap,
  of,
  forkJoin,
} from 'rxjs';
import { Feature, FeatureCollection } from 'geojson';
import * as Papa from 'papaparse';

import { ScenarioModel } from '@core/models/scenario.model';
import { ConstraintModel } from '@core/models/constraint.model';
import { ConstraintOption } from '@core/models/constraint-option.model';
import { MapLayerConfig } from '@core/models/map-layer-config.model';
import { ScenarioSummary } from '@core/models/scenario-summary.model';
import {
  mapLayers,
  scenarioConfig,
  scenarioSummaries,
} from '@core/config/map-config';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private constraintsUrl = 'assets/data/constraints.json';

  private _allScenarios!: ScenarioModel[];

  constructor(private httpClient: HttpClient) {}

  getAllScenarios() {
    return this._allScenarios;
  }

  setAllScenarios(scenarios: ScenarioModel[]) {
    this._allScenarios = scenarios;
  }

  loadScenarioDescriptions(): Observable<ScenarioSummary[]> {
    return this.httpClient
      .get(`assets/data/${scenarioSummaries.filename}`, {
        responseType: 'text',
      })
      .pipe(
        map(data => this.summaryCSVToArray(data)),
        catchError(this.handleError)
      );
  }

  /**
   * Load csv file of scenario runs
   */
  loadScenarios(): Observable<ScenarioModel[]> {
    return this.httpClient
      .get(`assets/data/${scenarioConfig.filename}`, {
        responseType: 'text',
      })
      .pipe(
        map(data => this.csvToArray(data)),
        tap((scenarios: ScenarioModel[]) => {
          this.setAllScenarios(scenarios);
        }),
        catchError(this.handleError)
      );
  }

  /**
   * Load constraints data
   */
  loadConstraints() {
    return this.httpClient
      .get<ConstraintModel[]>(this.constraintsUrl)
      .pipe(catchError(this.handleError));
  }

  /**
   * Convert csv file into an array of objects
   * @param csv csv file
   * @returns Array csv rows
   */
  csvToArray(csv: string): any[] {
    const lines = csv.split(`\n`);
    const headers = lines[0].split(`,`).map(s => s.replace(/\s/g, ''));
    const rows = lines.slice(1).map(r => r.split(`,`));
    return rows.map(rowValues => {
      const row: { [key: string]: string | number | null } = {};
      headers.forEach((header: string, idx: number) => {
        row[header] =
          idx < rowValues.length ? rowValues[idx].replace(/\s/g, '') : null;
      });
      return row as any;
    });
  }

  summaryCSVToArray(csv: string): any {
    const parsed = Papa.parse(csv, { header: true });
    return parsed.data;
  }

  /**
   * Convert scenario data to geojson
   * @param scenario scenarios to convert to geojson
   * @returns feature collection of scenario points
   */
  toGeoJSON(scenario: ScenarioModel[]): FeatureCollection {
    const features = scenario.map((s: ScenarioModel) => {
      return {
        type: 'Feature',
        properties: {
          id: s.cell_id,
          foundation: s.foundation,
          ports: s.ports,
          substations: s.substations,
          coastal_buffer: s.coastal_buffer,
          environmental: s.environmental,
          fishing: s.fishing,
          shipping: s.shipping,
        },
        geometry: {
          type: 'Point',
          coordinates: [+s.long, +s.lat],
        },
      } as Feature;
    });
    return {
      type: 'FeatureCollection',
      features,
    };
  }

  /**
   * Filters scenario data based on user
   * selected constrain options
   * @param selectedOptions constraint options
   * @returns ScenarioModel[] Filtered scenario
   */
  filterScenarios(
    selectedOptions: ConstraintOption,
    lcoeState: ConstraintOption
  ): Observable<ScenarioModel[]> {
    const coastal_buffer = selectedOptions['Coastal Buffer'];
    const shipping = selectedOptions['Shipping'];
    const environmental = selectedOptions['Environmental Designations'];
    const fishing = selectedOptions['Fishing'];
    const { Ports, Substations } = lcoeState;
    const scenarios = this.getAllScenarios();
    return of(
      scenarios.filter(
        (s: ScenarioModel) =>
          s.coastal_buffer === coastal_buffer &&
          s.shipping === shipping &&
          s.environmental === environmental &&
          s.fishing === fishing &&
          s.ports === Ports &&
          s.substations === Substations
      )
    );
  }

  /**
   * Loads all spatial data
   * @returns FeatureCollection[] Array of geojson
   */
  loadSpatialData(): Observable<FeatureCollection[]> {
    const requests = mapLayers.map((mapLayerConfig: MapLayerConfig) =>
      this.httpClient.get<FeatureCollection>(
        `assets/data/${mapLayerConfig.filename}`
      )
    );
    /** combine spatial data with layer config data */
    return forkJoin(requests).pipe(
      map((data: FeatureCollection[]) => data),
      catchError(this.handleError)
    );
  }

  /**
   * Error handler
   * @param err Http error response
   * @returns error
   */
  private handleError(err: HttpErrorResponse): Observable<never> {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(() => new Error(errorMessage));
  }
}
