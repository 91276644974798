<div class="constraint-control-container">
  <div class="header">
    <mat-label
      for="constraint-select-{{
        constraint.constraintName?.toLocaleLowerCase()?.replace(' ', '-')
      }}">
      {{ constraint.constraintName }}
    </mat-label>
    <button
      mat-icon-button
      disableRipple="true"
      aria-label="Open instructions"
      (click)="openInstructions.emit(constraint.stepIndex)">
      <arc-icon name="info-circle" size="medium"></arc-icon>
    </button>
  </div>
  <div class="select-container">
    <mat-form-field class="input__select">
      <mat-select
        id="constraint-select-{{
          constraint.constraintName?.toLocaleLowerCase()?.replace(' ', '')
        }}"
        [attr.name]="constraint.constraintName"
        (selectionChange)="constraintChange($event)"
        [(ngModel)]="selected">
        <mat-option
          *ngFor="let option of constraint.options"
          [value]="option.name">
          {{ option.description }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<ng-content></ng-content>
