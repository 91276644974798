import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { ConstraintControls } from '@store/actions';
import { Observable, tap } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

import * as fromRoot from '@store/index';

import { ConstraintModel } from '@core/models/constraint.model';
import { ConstraintOption } from '@core/models/constraint-option.model';
@Component({
  selector: 'scale-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlsComponent implements OnInit {
  constraintOptions$!: Observable<ConstraintModel[]>;
  scenarioFiltering$!: Observable<boolean>;
  selectedConstraintOptions$: Observable<ConstraintOption>;
  selectedConstraintOptions: ConstraintOption = {};
  applyChanges = false;
  showControlPanel = false;

  constructor(
    private store: Store,
    private breakpointObserver: BreakpointObserver,
    private cdRef: ChangeDetectorRef
  ) {
    this.constraintOptions$ = this.store.select(fromRoot.selectConstraints);
    this.scenarioFiltering$ = this.store.select(
      fromRoot.selectScenarioFiltering
    );
    this.selectedConstraintOptions$ = this.store
      .select(fromRoot.selectConstraintOptions)
      .pipe(tap(options => (this.selectedConstraintOptions = options)));
  }

  ngOnInit(): void {
    this.breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.XSmall])
      .subscribe(result => {
        this.showControlPanel = !result.matches;
        this.cdRef.detectChanges();
      });
  }

  /**
   * Apply selected constraints to filter scenarios
   */
  apply(): void {
    this.store.dispatch(
      ConstraintControls.setConstraintOptions({
        constraintOptions: this.selectedConstraintOptions,
      })
    );
    this.applyChanges = false;
  }

  openInstructions(): void {
    this.store.dispatch(ConstraintControls.openInstructions({ stepIndex: 3 }));
  }

  openInfo(stepIndex: number): void {
    this.store.dispatch(ConstraintControls.openInstructions({ stepIndex }));
  }

  openControlPanel() {
    this.store.dispatch(ConstraintControls.openControlPanel());
  }

  /**
   * Update state with selected constraint option
   * @param constraintOption { [key: string]: value }
   */
  setConstraint(constraintOption: ConstraintOption) {
    this.applyChanges = true;
    this.selectedConstraintOptions = {
      ...this.selectedConstraintOptions,
      ...constraintOption,
    };
  }
}
