import { ConstraintOption } from '@core/models/constraint-option.model';
import { createAction, props } from '@ngrx/store';

export const setControlPanelConstraints = createAction(
  '[Control Panel] Set Constraint Options',
  props<{ constraintOptions: ConstraintOption }>()
);

export const applyConstraintChanges = createAction(
  '[Control Panel] Apply Constraint Changes'
);
