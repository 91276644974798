import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'scale-left-panel',
  templateUrl: './left-panel.component.html',
  styleUrls: ['./left-panel.component.scss'],
})
export class LeftPanelComponent {
  @Output() openWelcome: EventEmitter<null> = new EventEmitter<null>();
}
