<div class="instructions__container">
  <h1>{{ constraint.title }}</h1>
  <div
    class="instructions__static instructions__static--{{
      constraint.imagePosition
    }}">
    <div class="static-text">
      <h2>{{ constraint.subtitle }}</h2>
      <div [innerHTML]="constraint.description"></div>
    </div>
    <img src="assets/instructions/{{ constraint.image }}" alt="" />
  </div>
</div>
