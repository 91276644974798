import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';

import { ConstraintModel } from '@core/models/constraint.model';
import { ConstraintOption } from '@core/models/constraint-option.model';

@Component({
  selector: 'scale-constraint',
  templateUrl: './constraint.component.html',
  styleUrls: ['./constraint.component.scss'],
})
export class ConstraintComponent {
  @Input() constraint!: ConstraintModel;
  @Input() selected!: string;
  @Output() setConstraint: EventEmitter<ConstraintOption> =
    new EventEmitter<ConstraintOption>();
  @Output() openInstructions: EventEmitter<number> = new EventEmitter<number>();

  constraintChange($event: MatSelectChange) {
    this.setConstraint.emit({
      [this.constraint.constraintName as string]: $event.value,
    });
  }
}
