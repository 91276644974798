import { MapLayerConfig } from '@core/models/map-layer-config.model';
import { ScenarioModel } from '@core/models/scenario.model';
import { createAction, props } from '@ngrx/store';

export const loadScenarios = createAction('[Scenario] Load Scenarios');

export const loadScenarioConfigSuccess = createAction(
  '[Scenario] Load Scenario Config Success',
  props<{ scenarioConfig: MapLayerConfig }>()
);

export const loadScenarioConfigFailure = createAction(
  '[Scenario] Load Scenarios Config Failure',
  props<{ error: any }>()
);

export const loadScenariosSuccess = createAction(
  '[Scenario] Load Scenario Success'
);

export const filterScenarios = createAction(
  '[Scenario] Filtered Scenarios',
  props<{ scenario: ScenarioModel[] }>()
);

export const loadScenariosFailure = createAction(
  '[Scenario] Load Scenarios Failure',
  props<{ error: any }>()
);
