import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { mergeMap } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Action, Store } from '@ngrx/store';

import * as fromRoot from '@store/index';

import { ConstraintModel } from '@core/models/constraint.model';

import {
  ConstraintControls,
  LayerListActions,
  LeftPanelActions,
} from '@store/actions/';

import { InstructionDialogComponent } from '@containers/instruction-dialog/instruction-dialog.component';
import { ControlPanelDialogComponent } from '@containers/control-panel-dialog/control-panel-dialog.component';

@Injectable()
export class DialogEffects {
  openDialog$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          ConstraintControls.openInstructions,
          LayerListActions.openInstructions,
          LeftPanelActions.openWelcome
        ),
        concatLatestFrom(() =>
          this.store.select(fromRoot.selectAllConstraints)
        ),
        mergeMap(([action, constraints]) => {
          const { stepIndex } = action;
          const dialogRef = this.dialog.open(InstructionDialogComponent, {
            data: { constraints, stepIndex },
            panelClass: 'instruction-dialog',
          });
          return dialogRef.afterClosed();
        })
      );
    },
    {
      dispatch: false,
    }
  );

  openControlPanelDialog$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ConstraintControls.openControlPanel),
        concatLatestFrom(() =>
          this.store.select(fromRoot.selectAllConstraints)
        ),
        mergeMap(([action, constraints]: [Action, ConstraintModel[]]) => {
          const dialogRef = this.dialog.open(ControlPanelDialogComponent, {
            data: constraints,
            panelClass: 'control-panel-dialog',
          });
          return dialogRef.afterClosed();
        })
      );
    },
    {
      dispatch: false,
    }
  );

  constructor(
    private actions$: Actions,
    public dialog: MatDialog,
    private store: Store
  ) {}
}
