<div class="controls-container">
  <div class="control-elements">
    <h2>Using this tool</h2>
    <div class="button-container">
      <arc-button
        class="outlined"
        type="outlined"
        color="secondary"
        (click)="openInstructions()"
        >Instructions</arc-button
      >
      <arc-button
        *ngIf="showControlPanel"
        class="outlined"
        type="outlined"
        color="secondary"
        (click)="openControlPanel()"
        >Control Panel</arc-button
      >
    </div>
  </div>
  <scale-seperator [index]="0"></scale-seperator>
  <ng-container *ngIf="constraintOptions$ | async as constraintOptions">
    <ng-container *ngIf="selectedConstraintOptions$ | async as selectedOptions">
      <scale-constraint
        *ngFor="let constraint of constraintOptions; let i = index"
        [constraint]="constraint"
        [selected]="selectedOptions[constraint.constraintName ?? '']"
        (setConstraint)="setConstraint($event)"
        (openInstructions)="openInfo($event)">
        <scale-seperator [index]="i"></scale-seperator>
      </scale-constraint>
      <div
        class="apply-button-container"
        [style.justify-content]="applyChanges ? 'space-between' : 'flex-end'">
        <p *ngIf="applyChanges">Please click apply</p>
        <arc-button (click)="apply()" [loading]="scenarioFiltering$ | async"
          >Apply</arc-button
        >
      </div>
    </ng-container>
  </ng-container>
</div>
