<div class="item-container">
  <div
    *ngIf="layerItem.layerType !== 'Scenario'"
    class="layer-swatch"
    [style.background-color]="backgroundColor"
    [style.opacity]="opacity"
    [style.border-color]="borderColor"
    [style.border-style]="borderStyle"
    [style.border-width]="borderWidth"
    [style.height]="height"
    [style.border-radius]="borderRadius"></div>
  <div class="item-col">
    <div class="name-row">
      <p>{{ layerItem.displayName }}</p>
    </div>
    <div class="checkbox-row">
      <p>{{ layerState }}</p>
      <mat-checkbox
        labelPosition="before"
        [checked]="selected"
        (change)="setLayerVisibility($event)"
        >on/off</mat-checkbox
      >
    </div>
  </div>
</div>
