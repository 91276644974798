<div class="instructions__container">
  <h1>{{ constraint.title }}</h1>
  <div class="instructions__selection">
    <img src="assets/instructions/{{ constraint.image }}" alt="" />
    <h2>{{ constraint.subtitle }}</h2>
    <p>{{ constraint.description }}</p>
    <p *ngIf="constraint.subtitle !== 'View Results'">
      Please choose an option below
    </p>
    <div class="selection-buttons">
      <button
        mat-flat-button
        *ngFor="let option of constraint.options"
        [ngClass]="selectedOption === option.name ? 'selected' : ''"
        (click)="selectConstraint(option.name)">
        {{ option.name }}
        <br />
        <span
          *ngIf="constraint.constraintName === 'LCOE Drivers'"
          class="description"
          >{{ option.description }}</span
        >
      </button>
    </div>
  </div>
</div>
